<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center"> Accounts </v-row>
        <v-row>
          <v-col>
            <v-select v-model="facilityId" :items="facilities" label="Facility"> </v-select>
          </v-col>
          <v-col>
            <v-select v-model="month" :items="months" label="Month"> </v-select>
          </v-col>
          <v-col>
            <v-select v-model="year" :items="years" label="Year"> </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="secondary" @click="queryAccounts"> Search </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-slider
              v-model="lockValue"
              append-icon="fa-unlock"
              prepend-icon="fa-lock"
              :min="min"
              :max="max"
              :inverse-label="!isLocked"
              :disabled="accounts.length <= 0"
              hint="Unlock to Publish"
              persistent-hint
            >
            </v-slider>
          </v-col>
          <v-col>
            <v-btn :disabled="isLocked" color="accent" @click="publish"> Publish Invoices </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <AccountsTable
              :accounts="accounts"
              @viewInvoice="viewInvoice($event)"
              @sendEmail="sendEmail($event)"
            >
            </AccountsTable>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AccountsTable from "@/components/accounts/AccountsTable.vue";
import months from "@/utility/months";
import facilities from "@/utility/facilities";

export default {
  name: "Accounts",
  components: {
    AccountsTable,
  },
  data: () => ({
    facilities, // shorthand
    months, // shorthand
    years: [2020, 2021, 2022, 2023],
    facilityId: 1,
    month: 1,
    year: 2020,
    min: 0,
    max: 1,
    lockValue: 0,
  }),
  computed: {
    accounts() {
      return this.$store.getters["accounts/accounts"];
    },
    isLocked() {
      return this.lockValue <= 0;
    },
    url() {
      return this.$store.getters["accounts/invoiceUrl"];
    },
  },
  methods: {
    queryAccounts() {
      const query = {
        facilityId: this.facilityId,
        year: this.year,
        month: this.month,
      };
      this.$store.dispatch("accounts/GetAccounts", query);
    },
    publish() {
      const query = {
        facilityId: this.facilityId,
        month: this.month,
        year: this.year,
      };
      this.$store.dispatch("accounts/PublishInvoices", query).then(() => {
        this.lockValue = 0;
      });
    },
    viewInvoice(e) {
      this.$store.dispatch("accounts/GetInvoicePDF", e).then(() => {
        window.open(this.url);
      });
    },
    sendEmail(e) {
      this.$store.dispatch("accounts/SendInvoiceEmail", e);
    },
  },
  mounted() {
    if (this.$route.query.facilityId && this.$route.query.year && this.$route.query.month) {
      this.facilityId = this.$route.query.facilityId;
      this.year = this.$route.query.year;
      this.month = this.$route.query.month;
    }
    this.queryAccounts();
  },
};
</script>
