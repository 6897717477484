<template>
  <v-card>
    <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="20"
      :search="search"
      item-key="accountNumber"
      class="elevation-1"
    >
      <template v-slot:item.email="{ item }">
        {{ item.email }}
      </template>
      <template v-slot:item.raAccount="{ item }">
        <span v-if="item.raAccount">
          {{ item.raAccount.accountNumber }} - {{ item.raAccount.name }}
        </span>
        <span v-else
          class="text--secondary"
        >No Associated Account</span>
      </template>
      <template v-slot:item.clubAccount="{ item }">
        <span v-if="item.clubAccount">
          {{ item.clubAccount.accountNumber }} - {{ item.clubAccount.name }}
        </span>
        <span v-else
          class="text--secondary"
        >No Associated Account</span>
      </template>
      <template v-slot:item.terraceAccount="{ item }">
        <span v-if="item.terraceAccount">
          {{ item.terraceAccount.accountNumber }} - {{ item.terraceAccount.name }}
        </span>
        <span v-else
          class="text--secondary"
        >No Associated Account</span>
      </template>
      <template v-slot:item.isEnabled="{ item }">
        {{ item.isEnabled ? 'Active' : 'Disabled' }}
      </template>
      <template v-slot:item.isVerified="{ item }">
        <v-icon
          v-if="item.isVerified"
          color='green'
        >
          fa-check
        </v-icon>
        <span v-else
          class="text--secondary"
        >Un-verified</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <span class="grid-button-wrapper">
          <v-btn
            small
            class="pa-2"
            @click="editUser(item)"
          >
            <v-icon small>
              fas fa-user-edit
            </v-icon>
          </v-btn>
        </span>
        <span class="grid-button-wrapper">
          <v-btn
            v-if="item.isVerified"
            small
            class="pa-2"
            @click="sendEmail(item.userId)"
            :color="sentEmails.includes(item.userId) ? 'green' : 'grey'"
          >
            <v-icon small>
              fa-envelope
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            small
            @click="verifyEmail(item.userId)"
            color="blue"
          >
            <v-icon
              small
              class="pr-2"
            >
              fa-check
            </v-icon>Verify Email
          </v-btn>
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'UserTable',
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Account Status',
        sortable: true,
        value: 'isEnabled',
      },
      {
        text: 'First Name',
        align: 'start',
        sortable: false,
        value: 'firstName',
      },
      {
        text: 'Last Name',
        align: 'start',
        sortable: false,
        value: 'lastName',
      },
      {
        text: 'RA Account',
        align: 'start',
        sortable: false,
        value: 'raAccount',
      },
      {
        text: 'Club Account',
        align: 'start',
        sortable: false,
        value: 'clubAccount',
      },
      {
        text: 'Terrace Account',
        align: 'start',
        sortable: false,
        value: 'terraceAccount',
      },
      {
        text: 'Email',
        sortable: false,
        value: 'email',
      },
      {
        text: 'Email Verified',
        sortable: true,
        value: 'isVerified',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      },
    ],
  }),
  computed: {
    sentEmails() {
      return this.$store.getters['customers/sentEmails'];
    },
  },
  methods: {
    editUser(user) {
      this.$emit('editUser', user);
    },
    sendEmail(userId) {
      this.$emit('sendEmail', userId);
    },
    verifyEmail(userId) {
      this.$emit('verifyEmail', userId);
    },
  },
};
</script>

<style scoped lang="scss">
.v-icon {
  text-decoration: none;
}

.grid-button-wrapper {
  padding: 2px;
}
</style>
