<template>
  <div v-if="!loading">
    <v-row v-if="excelErrors && excelErrors.length > 0 && excelErrors.successMessage !== ''">
      <v-col>
         <v-alert
          type="success"
          color="accent"
          outlined
          prominent
          border="left"
        >
          <h2>{{ excelErrors.successMessage }}</h2>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="excelErrors.duplicateErrors && excelErrors.duplicateErrors.length > 0">
      <v-col>
        <v-alert type="warning"
          color="purple"
          outlined
          prominent
          border="left"
        >
          <h2>Existing User Emails</h2>
          <ul>
            <li v-for="(duplicateError, index) in excelErrors.duplicateErrors"
                :key="index"
              >
              {{ duplicateError.columnName }}{{ duplicateError.rowNumber }} -
               {{ duplicateError.message }}
            </li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="excelErrors.emailErrors && excelErrors.emailErrors.length > 0">
      <v-col>
        <v-alert type="warning"
          color="purple"
          outlined
          prominent
          border="left"
        >
          <h2>Emails Missing</h2>
          <ul>
            <li v-for="(emailError, index) in excelErrors.emailErrors"
                :key="index"
              >
              {{ emailError.columnName }}{{ emailError.rowNumber }} - {{ emailError.message }}
            </li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="excelErrors.nameErrors && excelErrors.nameErrors.length > 0">
      <v-col>
        <v-alert type="warning"
          color="purple"
          outlined
          prominent
          border="left"
        >
          <h2>Names Missing</h2>
          <ul>
            <li v-for="(nameError, index) in excelErrors.nameErrors"
                :key="index"
              >
              {{ nameError.columnName }}{{ nameError.rowNumber }} - {{ nameError.message }}
            </li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="excelErrors.accountErrors && excelErrors.accountErrors.length > 0">
      <v-col>
        <v-alert type="warning"
          color="purple"
          outlined
          prominent
          border="left"
        >
          <h2>Invalid Account Numbers</h2>
          <ul>
            <li v-for="(accountError, index) in excelErrors.accountErrors"
              :key="index">
              {{ accountError.columnName }}{{ accountError.rowNumber }} - {{ accountError.message }}
            </li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'UserUploadErrors',
  props: {
    excelErrors: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
