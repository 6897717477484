<template>
  <v-container fluid>
    <v-row>
      <v-col cols=12>
        <CustomerTabs :users="users">
        </CustomerTabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerTabs from '@/components/customers/CustomerTabs.vue';

export default {
  name: 'Customers',
  components: {
    CustomerTabs,
  },
  computed: {
    users() {
      return this.$store.getters['customers/users'];
    },
  },
  mounted() {
    this.$store.dispatch('customers/GetUsers');
    this.$store.dispatch('accounts/GetAccountListItems', 1);
    this.$store.dispatch('accounts/GetAccountListItems', 2);
    this.$store.dispatch('accounts/GetAccountListItems', 3);
  },
};
</script>
