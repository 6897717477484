var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":20,"search":_vm.search,"item-key":"accountNumber"},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.raAccount",fn:function(ref){
var item = ref.item;
return [(item.raAccount)?_c('span',[_vm._v(" "+_vm._s(item.raAccount.accountNumber)+" - "+_vm._s(item.raAccount.name)+" ")]):_c('span',{staticClass:"text--secondary"},[_vm._v("No Associated Account")])]}},{key:"item.clubAccount",fn:function(ref){
var item = ref.item;
return [(item.clubAccount)?_c('span',[_vm._v(" "+_vm._s(item.clubAccount.accountNumber)+" - "+_vm._s(item.clubAccount.name)+" ")]):_c('span',{staticClass:"text--secondary"},[_vm._v("No Associated Account")])]}},{key:"item.terraceAccount",fn:function(ref){
var item = ref.item;
return [(item.terraceAccount)?_c('span',[_vm._v(" "+_vm._s(item.terraceAccount.accountNumber)+" - "+_vm._s(item.terraceAccount.name)+" ")]):_c('span',{staticClass:"text--secondary"},[_vm._v("No Associated Account")])]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isEnabled ? 'Active' : 'Disabled')+" ")]}},{key:"item.isVerified",fn:function(ref){
var item = ref.item;
return [(item.isVerified)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" fa-check ")]):_c('span',{staticClass:"text--secondary"},[_vm._v("Un-verified")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"grid-button-wrapper"},[_c('v-btn',{staticClass:"pa-2",attrs:{"small":""},on:{"click":function($event){return _vm.editUser(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-user-edit ")])],1)],1),_c('span',{staticClass:"grid-button-wrapper"},[(item.isVerified)?_c('v-btn',{staticClass:"pa-2",attrs:{"small":"","color":_vm.sentEmails.includes(item.userId) ? 'green' : 'grey'},on:{"click":function($event){return _vm.sendEmail(item.userId)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-envelope ")])],1):_c('v-btn',{attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.verifyEmail(item.userId)}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v(" fa-check ")]),_vm._v("Verify Email ")],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }