<template>
  <v-form v-model="isValid">
    <v-row>
      <v-col md="6">
        <v-text-field
          v-model="customer.firstName"
          label="First Name"
          required
          class="pa-2"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          v-model="customer.lastName"
          label="Last Name"
          required
          class="pa-2"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          v-model="customer.email"
          :rules="emailRules"
          label="Email"
          required
          disabled
          class="pa-2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <BaseAccountSelectList
          v-model="customer.raAccount"
          label="Select RA Account"
          :items="raAccounts"
        >
        </BaseAccountSelectList>
        <!-- <v-text-field
          v-model="customer.raAccountNumber"
          label="RA Account #"
          required
          class="pa-2"
        ></v-text-field> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <BaseAccountSelectList
          v-model="customer.clubAccount"
          label="Select Club Account"
          :items="clubAccounts"
        >
        </BaseAccountSelectList>
        <!-- <v-text-field
          v-model="customer.clubAccountNumber"
          label="Club Account #"
          required
          class="pa-2"
        ></v-text-field> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2">
        <v-btn
          width="100%"
          color="accent"
          @click="updateUser"
        >
          Update Customer
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col v-if="user.isEnabled"
        md="2">
        <v-slider
          v-model="lockValue"
          append-icon="fa-unlock"
          prepend-icon="fa-lock"
          :min="0"
          :max="1"
          :inverse-label="lockValue > 0"
          :disabled="!user.isEnabled"
          hint="Unlock to Disable"
          persistent-hint
        >
        </v-slider>
      </v-col>
      <v-col v-if="user.isEnabled"
        md="2">
        <v-btn
          width="100%"
          color="error"
          :disabled="lockValue < 1"
          @click="disableUser"
        >
          Disable Customer
        </v-btn>
      </v-col>
      <v-col v-else
        md="2">
        <v-btn
          width="100%"
          color="accent"
          @click="enableUser"
        >
          Enable Customer
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import BaseAccountSelectList from '@/components/base/BaseAccountSelectList.vue';

export default {
  name: 'CustomerEditForm',
  components: {
    BaseAccountSelectList,
  },
  props: ['user'],
  data() {
    return {
      customer: { ...this.user },
      isValid: true,
      lockValue: 0,
      emailRules: [
        (v) => !!v || 'Username Required',
        (v) => new RegExp(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/).test(v) || 'Not a valid Email',
      ],
    };
  },
  computed: {
    raAccounts() {
      return this.$store.getters['accounts/raAccounts'];
    },
    clubAccounts() {
      return this.$store.getters['accounts/clubAccounts'];
    },
  },
  methods: {
    updateUser() {
      this.$emit('updateUser', this.customer);
    },
    disableUser() {
      this.$emit('disableUser', this.customer);
    },
    enableUser() {
      this.$emit('enableUser', this.customer);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
