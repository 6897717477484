<template>
  <div>
    <v-app-bar
      color="primary"
      collapse-on-scroll
      dense
      dark
    >
      <v-app-bar-nav-icon @click="drawer = true">
        <v-icon>fa-bars</v-icon>
      </v-app-bar-nav-icon>
      <span>
        Peachtree Hills
      </span>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <NavItems
        title="Peachtree Hills"
      >
      </NavItems>
    </v-navigation-drawer>
    <router-view/>
  </div>
</template>

<script>
import NavItems from '@/components/nav/NavItems.vue';

export default {
  name: 'Nav',
  components: {
    NavItems,
  },
  data: () => ({
    drawer: false,
  }),
};
</script>

<style scope lang="scss">
.nav-header {
  font-size: 20px;
  font-weight: bold;
}
</style>
