<template>
  <v-card>
    <v-card-title>
        Account Invoices
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    <v-data-table
      :headers="headers"
      :items="accounts"
      :items-per-page="10"
      :search="search"
      item-key="invoiceId"
      class="elevation-1"
    >
      <template v-slot:item.total="{ item }">
        ${{ item.total | currency }}
      </template>
      <template v-slot:item.actions="{ item }">
        <span class="grid-button-wrapper">
          <v-btn small
            @click="view(item.invoiceId)"
          >
            <v-icon small>
              fa-eye
            </v-icon>
          </v-btn>
        </span>
        <span class="grid-button-wrapper">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="blue"
                v-bind="attrs"
                v-on="on"
                :disabled="item.users.length <= 0"
              >
                <v-icon small>
                  fa-envelope
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(user, index) in item.users"
                :key="index"
                :disabled="!user.isVerified"
                @click="sendEmail(item.invoiceId, user.userId)"
              >
                <v-list-item-title>{{ user.name }} - {{ user.email}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'AccountsTable',
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Account #',
        align: 'start',
        sortable: false,
        value: 'accountNumber',
      },
      {
        text: 'Invoice Id',
        align: 'start',
        sortable: false,
        value: 'invoiceId',
      },
      {
        text: 'Invoice Total',
        align: 'start',
        sortable: false,
        value: 'total',
      },
      {
        text: 'First Name',
        sortable: false,
        value: 'firstName',
      },
      {
        text: 'Last Name',
        sortable: false,
        value: 'lastName',
      },
      {
        text: 'Published?',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      },
    ],
  }),
  getters: {
    token: (state) => state.token,
  },
  methods: {
    view(invoiceId) {
      this.$emit('viewInvoice', invoiceId);
    },
    sendEmail(invoiceId, userId) {
      this.$emit('sendEmail', { invoiceId, userId });
    },
  },
};
</script>

<style scoped lang="scss">
.v-icon {
  text-decoration: none;
}

.grid-button-wrapper {
  padding: 2px;
}
</style>
