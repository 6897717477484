<template>
  <v-list
  nav
  dense
  >
    <v-list-item-group>
      <v-list-item>
        <v-list-item-title>
          <img
            src="@/assets/headerLogo.png"
            width="200"
          />
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="navigate('InvoiceUpload')">
        <v-list-item-icon>
          <v-icon>fa-cloud</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          Upload Invoice
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="navigate('Accounts')">
        <v-list-item-icon>
          <v-icon>fa-user-friends</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          Accounts
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="navigate('Reports')">
        <v-list-item-icon>
          <v-icon>fa-chart-pie</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          Reports
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="navigate('Customers')">
        <v-list-item-icon>
          <v-icon>fas fa-user-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          Customers
        </v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'NavItems',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    navigate(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style scoped lang="scss">
.navHeader {
  font-size: 20px;
  font-weight: bold;
}
</style>
