<template>
  <div v-if="!loading && sessionId > 0">
    <v-row v-if="sessionErrors && sessionErrors.notFoundErrors.length > 0">
      <v-col>
        <v-alert type="warning"
          color="purple"
          outlined
          prominent
          border="left"
        >
          <h2>PDFs Not Found</h2>
          <ul>
            <li v-for="(notFoundError, index) in sessionErrors.notFoundErrors"
                :key="index"
              >
              {{ notFoundError.accountNumber }} {{ notFoundError.message }}
            </li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="sessionErrors && sessionErrors.recordNotFoundErrors.length > 0">
      <v-col>
        <v-alert type="warning"
          color="purple"
          outlined
          prominent
          border="left"
        >
          <h2>CSV Records Not Found</h2>
          <ul>
            <li v-for="(recordNotFoundError, index) in sessionErrors.recordNotFoundErrors"
                :key="index"
              >
              {{ recordNotFoundError.accountNumber }} {{ recordNotFoundError.message }}
            </li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="sessionErrors && sessionErrors.totalErrors.length > 0">
      <v-col>
        <v-alert type="warning"
          color="purple"
          outlined
          prominent
          border="left"
        >
          <h2>Total Mismatches</h2>
          <ul>
            <li v-for="(totalError, index) in sessionErrors.totalErrors"
              :key="index">
              {{ totalError.accountNumber }} {{ totalError.message }}
            </li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="sessionErrors && sessionErrors.facilityErrors.length > 0">
      <v-col>
        <v-alert type="warning"
          color="purple"
          outlined
          prominent
          border="left"
        >
          <h2>Facility Mismatches</h2>
          <ul>
            <li v-for="(facilityError, index) in sessionErrors.facilityErrors"
              :key="index">
              {{ facilityError.accountNumber }} {{ facilityError.message }}
            </li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'UploadErrors',
  props: {
    sessionId: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    sessionErrors: {
      type: Object,
      required: true,
    },
  },
};
</script>
