const facilities = [
  {
    value: 1,
    text: 'Residential Association',
  },
  {
    value: 2,
    text: 'Club',
  },
  {
    value: 3,
    text: 'Terraces',
  },
];

export default facilities;
