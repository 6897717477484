<template>
  <v-container fluid>
    <v-row>
      <v-col cols=12>
        <v-row v-if="loading">
          <v-col>
            <v-row justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="320"
              ></v-progress-circular>
            </v-row>
            <v-row justify="center"
              class="mt-3">
              <h2>Uploading. . .</h2>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="!loading">
          <v-col>
            <CustomerAddForm
              @addUser="addUser($event)"
              @addAdmin="addAdmin($event)"
            >
            </CustomerAddForm>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row v-if="!loading">
          <v-col>
            <UserUploadBox
              v-model="files"
              acceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                application/vnd.ms-excel"
              boxTitle="Batch Upload Users"
            >
            </UserUploadBox>
          </v-col>
        </v-row>
        <v-row
          v-if="!loading"
          justify="end"
        >
          <v-col md="2">
            <v-btn
              width="100%"
              color="accent"
              @click="downloadTemplate"
            >
              <v-icon
                small
                class="pr-2"
              >
                fas fa-file-download
              </v-icon>Template
            </v-btn>
          </v-col>
          <v-col md="2">
            <v-btn
              @click="upload"
              color="purple"
              width="100%"
            >
              Upload Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <UserUploadErrors
              :excelErrors="excelErrors"
              :loading="loading"
            >
            </UserUploadErrors>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserUploadBox from './user-upload/UserUploadBox.vue';
import UserUploadErrors from './user-upload/UserUploadErrors.vue';
import CustomerAddForm from './user-upload/CustomerAddForm.vue';

export default {
  name: 'UserUpload',
  components: {
    UserUploadBox,
    UserUploadErrors,
    CustomerAddForm,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    files: [],
    loading: false,
  }),
  computed: {
    excelErrors() {
      return this.$store.getters['customers/excelErrors'];
    },
  },
  methods: {
    downloadTemplate() {
      this.$store.dispatch('customers/GetExcelTemplate').then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'UsersTemplate.xlsx';
        link.click();
      });
    },
    upload() {
      this.loading = true;
      this.$store.dispatch('customers/UploadUsers', this.files).then(() => {
        this.loading = false;
        this.files = []; // clear files from component data
        this.$store.dispatch('customers/GetUsers'); // get new users, if any
      });
    },
    addUser(user) {
      this.$store.dispatch('customers/AddUser', user).then(() => {
        this.$store.dispatch('customers/GetUsers');
      });
    },
    addAdmin(user) {
      this.$store.dispatch('customers/AddAdmin', user).then(() => {
        this.$store.dispatch('customers/GetUsers');
      });
    },
  },
};
</script>

<style scoped lang="scss">
.v-icon {
  text-decoration: none;
}
</style>
