<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row v-if="loading">
          <v-col>
            <v-row justify="center">
              <v-progress-circular indeterminate color="info" :size="320"></v-progress-circular>
            </v-row>
            <v-row justify="center" class="mt-3">
              <h2>Uploading. . .</h2>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="!loading && sessionId <= 0">
          <v-col>
            <v-select v-model="facilityId" :items="facilities" label="Facility"> </v-select>
          </v-col>
          <v-col>
            <v-select v-model="month" :items="months" label="Month"> </v-select>
          </v-col>
          <v-col>
            <v-select v-model="year" :items="years" label="Year"> </v-select>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" v-if="!loading && sessionId <= 0">
          <v-col>
            <UploadBox
              v-model="pdfs"
              acceptType="application/pdf"
              boxTitle="Upload PDF"
            ></UploadBox>
          </v-col>
          <v-col>
            <UploadBox v-model="csvs" acceptType=".csv,.txt" boxTitle="Upload CSV"></UploadBox>
          </v-col>
        </v-row>
        <v-row v-if="!loading && sessionId <= 0" justify="end">
          <v-col md="2">
            <v-btn @click="upload" color="accent" width="100%"> Upload </v-btn>
          </v-col>
        </v-row>
        <UploadErrors :sessionId="sessionId" :loading="loading" :sessionErrors="sessionErrors">
        </UploadErrors>
        <v-row justify="end" v-if="sessionId > 0">
          <v-btn @click="cancel" color="error"> Cancel </v-btn>
          <v-btn @click="finalize" color="accent"> Finalize Upload </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UploadBox from "@/components/invoice-upload/UploadBox.vue";
import UploadErrors from "@/components/invoice-upload/UploadErrors.vue";
import months from "@/utility/months";
import facilities from "@/utility/facilities";

export default {
  name: "InvoiceUpload",
  components: {
    UploadBox,
    UploadErrors,
  },
  data: () => ({
    loading: false,
    facilities, // shorthand
    months, // shorthand
    years: [2020, 2021, 2022, 2023],
    facilityId: 1,
    month: 1,
    year: 2020,
    files: [],
    pdfs: [],
    csvs: [],
  }),
  computed: {
    sessionId() {
      return this.$store.getters["upload/uploadSessionId"];
    },
    sessionErrors() {
      return this.$store.getters["upload/uploadSessionErrors"];
    },
  },
  methods: {
    upload() {
      this.loading = true;
      this.files.push(...this.pdfs);
      this.files.push(...this.csvs);
      const model = {
        facilityId: this.facilityId,
        month: this.month,
        year: this.year,
        files: this.files,
      };
      this.$store.dispatch("upload/Upload", model).then(() => {
        this.loading = false;
        this.files = []; // clear files from component data
      });
    },
    finalize() {
      this.$store.dispatch("upload/FinalizeUpload", this.sessionErrors).then(() => {
        this.$router.push({
          name: "Accounts",
          query: {
            facilityId: this.sessionErrors.facilityId,
            year: this.sessionErrors.year,
            month: this.sessionErrors.month,
          },
        });
      });
    },
    cancel() {
      this.$store.commit("upload/SetUploadSessionId", 0);
    },
  },
};
</script>
