<template>
  <v-container
    fluid
    fill-height
  >
    <v-row justify="end">
      <v-col md="2">
        <v-btn
          width="100%"
          color="blue"
          @click="navigateBack"
        >
          Return
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CustomerEditForm
          :user="dataUser"
          @updateUser="updateUser($event)"
          @disableUser="disableUser($event)"
          @enableUser="enableUser($event)"
        >
        </CustomerEditForm>
      </v-col>
    </v-row>
    <v-row v-if="editErrors.errors && editErrors.errors.length > 0">
      <v-col>
        <v-alert type="warning"
          color="purple"
          outlined
          prominent
          border="left"
        >
          <h2>Account Number(s) do not Exist</h2>
          <ul>
            <li v-for="(editError, index) in editErrors.errors"
                :key="index"
              >
                {{ editError.message }}
            </li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerEditForm from '@/components/customers/CustomerEditForm.vue';

export default {
  name: 'EditCustomer',
  components: {
    CustomerEditForm,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataUser: { ...this.user },
    };
  },
  computed: {
    editErrors() {
      return this.$store.getters['customers/editErrors'];
    },
  },
  methods: {
    updateUser(user) {
      this.$store.dispatch('customers/EditUser', user).then(() => {
        this.$store.dispatch('customers/GetUsers');
      });
    },
    enableUser(user) {
      this.$store.dispatch('customers/EnableUser', user.userId).then((response) => {
        this.dataUser = response.data;
      });
    },
    disableUser(user) {
      this.$store.dispatch('customers/DisableUser', user.userId).then((response) => {
        this.dataUser = response.data;
      });
    },
    navigateBack() {
      this.$router.push({ name: 'Customers' });
    },
  },
};
</script>
