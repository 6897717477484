<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center"> Views Report </v-row>
        <v-row>
          <v-col>
            <v-select v-model="facilityId" :items="facilities" label="Facility"> </v-select>
          </v-col>
          <v-col>
            <v-select v-model="month" :items="months" label="Month"> </v-select>
          </v-col>
          <v-col>
            <v-select v-model="year" :items="years" label="Year"> </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="secondary" @click="queryAccounts"> Search </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <EmailStatistics :stats="stats"> </EmailStatistics>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <ViewsTable :accounts="accounts"> </ViewsTable>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ViewsTable from "@/components/reports/ViewsTable.vue";
import EmailStatistics from "@/components/reports/EmailStatistics.vue";

import months from "@/utility/months";
import facilities from "@/utility/facilities";

export default {
  name: "Reports",
  components: {
    ViewsTable,
    EmailStatistics,
  },
  data: () => ({
    facilities, // shorthand
    months, // shorthand
    years: [2020, 2021, 2022, 2023],
    facilityId: 1,
    month: 1,
    year: 2020,
  }),
  computed: {
    accounts() {
      return this.$store.getters["reports/accounts"];
    },
    stats() {
      return this.$store.getters["reports/emailStats"];
    },
  },
  methods: {
    queryAccounts() {
      const query = {
        facilityId: this.facilityId,
        year: this.year,
        month: this.month,
      };
      this.$store.dispatch("reports/GetAccounts", query);
      this.getStats();
    },
    getStats() {
      const query = {
        facilityId: this.facilityId,
        year: this.year,
        month: this.month,
      };
      this.$store.dispatch("reports/GetEmailStatistics", query);
    },
  },
  mounted() {
    this.queryAccounts();
    this.getStats();
  },
};
</script>
