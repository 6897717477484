<template>
  <v-form v-model="isValid">
    <v-row justify="start">
      <v-col md="4">
        <h2 class="header">Add Single Customer</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <v-text-field
          v-model="customer.firstName"
          label="First Name"
          required
          class="pa-2"
        ></v-text-field>
      </v-col>
      <v-col md="4">
        <v-text-field
          v-model="customer.lastName"
          label="Last Name"
          required
          class="pa-2"
        ></v-text-field>
      </v-col>
      <v-col md="4">
        <v-text-field
          v-model="customer.email"
          :rules="emailRules"
          label="Email"
          required
          class="pa-2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <BaseAccountSelectList
          v-model="customer.raAccount"
          label="Select RA Account"
          :items="raAccounts"
        >
        </BaseAccountSelectList>
        <!-- <v-text-field
          v-model="customer.raAccountNumber"
          label="RA Account #"
          required
          class="pa-2"
        ></v-text-field> -->
      </v-col>
      <v-col md="4">
        <BaseAccountSelectList
          v-model="customer.clubAccount"
          label="Select Club Account"
          :items="clubAccounts"
        >
        </BaseAccountSelectList>
        <!-- <v-text-field
          v-model="customer.clubAccountNumber"
          label="Club Account #"
          required
          class="pa-2"
        ></v-text-field> -->
      </v-col>
      <v-col md="4">
        <BaseAccountSelectList
          v-model="customer.terraceAccount"
          label="Select Terrace Account"
          :items="terraceAccounts"
        >
        </BaseAccountSelectList>
      </v-col>
      <v-col md="2">
        <v-checkbox
          v-model="customer.isAdmin"
          label="Make Admin?">
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col md="2">
        <v-btn
          width="100%"
          color="accent"
          :disabled="!isValid"
          @click="addUser"
        >
          Add Customer
        </v-btn>
      </v-col>
      <v-col md="10">
        <v-alert
          v-if="addErrors && addErrors.failureMessage"
          type="warning"
          color="error"
          outlined
          prominent
          border="left"
        >
          <h2>{{ addErrors.failureMessage }}</h2>
        </v-alert>
        <v-alert
          v-if="addErrors && addErrors.successMessage"
          type="success"
          color="accent"
          outlined
          prominent
          border="left"
        >
          <h2>{{ addErrors.successMessage }}</h2>
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import BaseAccountSelectList from '@/components/base/BaseAccountSelectList.vue';

export default {
  name: 'CustomerAddForm',
  components: {
    BaseAccountSelectList,
  },
  data() {
    return {
      customer: {},
      isValid: true,
      emailRules: [
        (v) => !!v || 'Email Required',
        (v) => new RegExp(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/).test(v) || 'Not a valid Email',
      ],
    };
  },
  computed: {
    addErrors() {
      return this.$store.getters['customers/addErrors'];
    },
    raAccounts() {
      return this.$store.getters['accounts/raAccounts'];
    },
    clubAccounts() {
      return this.$store.getters['accounts/clubAccounts'];
    },
    terraceAccounts() {
      return this.$store.getters['accounts/terraceAccounts'];
    },
  },
  methods: {
    addUser() {
      if (this.customer.isAdmin) {
        this.$emit('addAdmin', this.customer);
      } else {
        this.$emit('addUser', this.customer);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  color: black;
}
</style>
