<template>
  <v-autocomplete
    v-model="selectedAccount"
    :label="label"
    :items="items"
    :filter="customFilter"
    @change="onChange"
    clearable
  >
    <template v-slot:selection="{ item }">
      {{ item.accountNumber }} - {{ item.name }}
    </template>
    <template v-slot:item="{ item }">
      {{ item.accountNumber }} - {{ item.name }}
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'BaseAccountSelectList',
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: 'Select Account',
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedAccount: { ...this.value } || 0,
    };
  },
  methods: {
    customFilter(item, queryText, itemText) {
      const textOne = item.accountNumber.toString().toLowerCase();
      const textTwo = item.name.toLowerCase();
      const iText = itemText.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1
        || textTwo.indexOf(searchText) > -1
        || iText.indexOf(searchText) > -1;
    },
    onChange() {
      this.$emit('input', this.selectedAccount);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
