<template>
  <div>
    <v-row justify="space-between">
      <v-col md="2">
        <v-card>
          <v-card-text>
            <v-row align="center">
              <v-col>
                <p class="text-center header-display">Total</p>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col>
                <p class="text-center number-display">{{ stats.totalCount }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="2">
        <v-card>
          <v-card-text>
            <v-row align="center">
              <v-col>
                <p class="text-center header-display">Queued</p>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col>
                <p class="text-center number-display">{{ stats.queuedCount }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="2">
        <v-card>
          <v-card-text>
            <v-row align="center">
              <v-col>
                <p class="text-center header-display">Sent</p>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col>
                <p class="text-center number-display">{{ stats.sentCount }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="2">
        <v-card>
          <v-card-text>
            <v-row align="center">
              <v-col>
                <p class="text-center header-display">Delivered</p>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col>
                <p class="text-center number-display">{{ stats.deliveredCount }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="2">
        <v-card>
          <v-card-text>
            <v-row align="center">
              <v-col>
                <p class="text-center header-display">Opened</p>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col>
                <p class="text-center number-display">{{ stats.openedCount }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="2">
        <v-card>
          <v-card-text>
            <v-row align="center">
              <v-col>
                <p class="text-center header-display">Read</p>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col>
                <p class="text-center number-display">{{ stats.readCount }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'EmailStatistics',
  props: {
    stats: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
.v-icon {
  text-decoration: none;
}

.header-display {
  font-size: 1.5em;
  font-weight: bold;
}

.number-display {
  font-size: 1.2em;
}
</style>
