var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Account Invoices "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.accounts,"items-per-page":10,"search":_vm.search,"item-key":"invoiceId"},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"grid-button-wrapper"},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.view(item.invoiceId)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-eye ")])],1)],1),_c('span',{staticClass:"grid-button-wrapper"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"blue","disabled":item.users.length <= 0}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-envelope ")])],1)]}}],null,true)},[_c('v-list',_vm._l((item.users),function(user,index){return _c('v-list-item',{key:index,attrs:{"disabled":!user.isVerified},on:{"click":function($event){return _vm.sendEmail(item.invoiceId, user.userId)}}},[_c('v-list-item-title',[_vm._v(_vm._s(user.name)+" - "+_vm._s(user.email))])],1)}),1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }