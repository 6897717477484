<template>
  <div>
    <v-tabs
      v-model="tab"
      background-color="info"
      dark
    >
      <v-tab>
        <v-icon
          small
          class="pr-2"
        >
          fas fa-users
        </v-icon>
        Users
      </v-tab>
      <v-tab>
        <v-icon
          small
          class="pr-2"
        >
          fas fa-file-upload
        </v-icon>
        Create Users
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <UserTable
            :users="users"
            @editUser="navigateToEdit($event)"
            @sendEmail="sendEmail($event)"
            @verifyEmail="verifyEmail($event)"
          >
          </UserTable>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <UserUpload>
          </UserUpload>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import UserUpload from './customer-tabs/UserUpload.vue';
import UserTable from './customer-tabs/UserTable.vue';

export default {
  name: 'CustomerTabs',
  components: {
    UserUpload,
    UserTable,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tab: 0,
  }),
  methods: {
    navigateToEdit(user) {
      this.$router.push({ name: 'EditCustomer', params: { user } });
    },
    sendEmail(userid) {
      this.$store.dispatch('customers/SendEmail', userid).then(() => {
        this.$store.dispatch('customers/GetUsers');
      });
    },
    verifyEmail(userid) {
      this.$store.dispatch('customers/VerifyEmail', userid).then(() => {
        this.$store.dispatch('customers/GetUsers');
      });
    },
  },
};
</script>

<style scoped lang="scss">
.v-icon {
  text-decoration: none;
}
</style>
