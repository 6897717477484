<template>
  <v-card>
    <v-card-title>
        Views Report
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    <v-data-table
      :headers="headers"
      :items="accounts"
      :items-per-page="10"
      :search="search"
      item-key="invoiceId"
      class="elevation-1"
    >
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'ViewsTable',
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Account #',
        align: 'start',
        sortable: false,
        value: 'accountNumber',
      },
      {
        text: 'Invoice Id',
        align: 'start',
        sortable: false,
        value: 'invoiceId',
      },
      {
        text: 'First Name',
        sortable: false,
        value: 'firstName',
      },
      {
        text: 'Last Name',
        sortable: false,
        value: 'lastName',
      },
      {
        text: 'Last Email Status',
        sortable: false,
        value: 'lastEmailStatus',
      },
      {
        text: 'View Count',
        sortable: false,
        value: 'viewCount',
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
.v-icon {
  text-decoration: none;
}
</style>
