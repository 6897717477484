<template>
  <div
    @dragover.prevent
    @drop.prevent
  >
    <div>
      <span class="box-title">{{ boxTitle }}</span>
    </div>
    <div
      @drop="handleFileDrop"
      @click="handleClick"
      class="drop-wrapper"
    >
      <input
        type="file"
        ref="fileUpload"
        :accept="acceptType"
        @change="handleFileInput"
        multiple
      >
      <v-container>
        <v-row>
          <v-col
            v-for="(file, index) in files"
            :key="index"
          >
            <span>{{ file.name }} ({{ file.size | bytes }}) </span>
            <v-btn
              @click="removeFile(index)"
              x-small
            >
              <v-icon x-small>fa-times</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadBox',
  props: {
    boxTitle: {
      type: String,
      default: '',
    },
    acceptType: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    files: [],
  }),
  watch: {
    files() {
      this.$emit('input', this.files);
    },
  },
  methods: {
    handleClick() {
      const input = this.$refs.fileUpload;
      input.click();
    },
    handleFileDrop(e) {
      const { files } = e.dataTransfer;
      if (!files) return;
      this.files.push(...files);
    },
    onFileUpload() {
      const { fileUpload } = this.$refs;
      fileUpload.click();
    },
    handleFileInput(e) {
      const { files } = e.target;
      if (!files) return;
      this.files.push(...files);
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input {
  display: none;
}

.box-title {
  font-size: 20px;
  font-weight: bold;
}

.drop-wrapper {
  background: #f6f6f6;
  border: 4px dashed #ccc;
  border-radius: 2px;
  width: 100%;
  height: 100px;
  cursor: pointer;

  input {
    display: "none";
    margin: 0px !important;
  }
}
</style>
